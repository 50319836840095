import React from "react"
import Layout from "../components/layout.js"

export default () => (
  <div>
    <Layout>
      <h1>Contact Us</h1>

      <p>
        Send us an email at{" "}
        <a href="mailto:tom@runyon.dev?Subject=Runyon%20Solutions%20Introduction">
          tom@runyon.dev
        </a>{" "}
        or schedule a meeting with me on my{" "}
        <a href="https://calendly.com/tom-runyon">Calendar</a>
      </p>
    </Layout>
  </div>
)
